import React from "react";
import Admin from "../components/Admin";

function AdminPage() {
  return (
    <>
      <Admin />
    </>
  );
}

export default AdminPage;
