import React from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

function ProjectsMainPage() {
  return (
    <>
      <Navigation />
      <Footer />
    </>
  );
}

export default ProjectsMainPage;
